import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'

import 'leaflet/dist/leaflet.css'
import i18n from './i18n'
import { ApiService } from '@/services/api.service'
import { TokenService } from '@/services/storage.service'
import { statusNameFilter } from '@/filters'
import { City } from '@/services/city.service'
import { CityAPIModel } from '@/models'

Vue.config.productionTip = true
Vue.use(Buefy)

ApiService.init(`${process.env.VUE_APP_BACKEND_URL}/v1/`)

if (TokenService.getToken()) {
  ApiService.setHeader()
}
Vue.filter('status', statusNameFilter)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DNS || '',
    integrations: [],
    tracePropagationTargets: [
      process.env.VUE_APP_BACKEND_URL as string
    ]
  })
}

const initApp = () => {
  City.getCityConfig()
    .then((data: CityAPIModel) => {
      new Vue({
        router,
        store,
        i18n,
        render: h => h(App),
        created () {
          this.$store.commit('updateZoom', 17)
          this.$store.commit('updateCenter',
            data.center.coordinates.reverse()
          )
          this.$store.commit('setCityName', data.name)
          this.$store.commit('setCityImage', data.image)
          this.$store.commit('setCityText', data.text)
          this.$store.commit('setIfExternalDataConnected', data.isExternalDataConnected)
        }
      }).$mount('#app')
    })
    .catch(() => {
      console.log('Error fetching a city.')
    })
}

initApp()
