import { ApiService } from '@/services/api.service'
import { TokenService } from '@/services/storage.service'

export class AuthenticationError extends Error {
  errorCode: number

  constructor (errorCode: number, message: string) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

export const UserService = {
  async login (email: string, password: string) {
    try {
      const response = await ApiService.post('token/login/', {
        data: {
          type: 'TokenCreateView',
          attributes: {
            email,
            password
          }
        }
      })

      const token = response.data.data.attributes.authToken
      TokenService.saveToken(token)
      ApiService.setHeader()

      ApiService.mount401Interceptor()

      return token
    } catch (error) {
      let errorMessage = ''
      if (error.response.data.errors.length) {
        errorMessage = error.response.data.errors[0].detail
      }
      throw new AuthenticationError(error.response.status, errorMessage)
    }
  },

  async logout () {
    try {
      await ApiService.post('token/logout/', {})
    } catch (e) {
      console.log(e)
    }
    TokenService.removeToken()
    ApiService.removeHeader()
  },

  async resetPassword (email: string) {
    const response = await ApiService.post('users/reset_password/', {
      data: {
        type: 'UserViewSet',
        attributes: {
          email
        }
      }
    })
  },

  async getUsersDetails () {
    const response = await ApiService.get('users/me/')
    return {
      email: response.data.data.attributes.email
    }
  },
  async resetPasswordConfirm (newPasswordParams: any) {
    const response = await ApiService.post('users/reset_password_confirm/', {
      data: {
        type: 'UserViewSet',
        attributes: {
          ...newPasswordParams
        }
      }
    })
  }
}
