import { render, staticRenderFns } from "./AccidentDetailsExtraInfo.vue?vue&type=template&id=58cc1a88&scoped=true"
import script from "./AccidentDetailsExtraInfo.vue?vue&type=script&lang=ts"
export * from "./AccidentDetailsExtraInfo.vue?vue&type=script&lang=ts"
import style0 from "./AccidentDetailsExtraInfo.vue?vue&type=style&index=0&id=58cc1a88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58cc1a88",
  null
  
)

export default component.exports