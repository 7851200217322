
import Component from 'vue-class-component'
import Vue from 'vue'
import moment from 'moment'
import { Action } from 'vuex-class'
import { GenerateReportPayload } from '@/store/types'

  @Component
export default class Reports extends Vue {
    private loading = false
    private dateRange: GenerateReportPayload = {
      from: null,
      to: null
    }

    private errors = {
      from: false,
      to: false
    }

    @Action('accidents/generateReport')
    generateReport!: (payload: GenerateReportPayload) => {}

    submit () {
      // error validation
      this._resetErrors()
      if (!this.dateRange.from) {
        this.errors.from = true
      }

      if (!this.dateRange.to) {
        this.errors.to = true
      }

      if (this.errors.from || this.errors.to) {
        return
      }

      this._resetErrors()
      this.loading = true
      this.generateReport(this.dateRange)
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }

    rangeLastMonth () {
      this.dateRange.to = moment().toDate()
      this.dateRange.from = moment().subtract(1, 'months').toDate()
    }

    rangePreviousMonth () {
      this.dateRange.to = moment().subtract(1, 'month').endOf('month').toDate()
      this.dateRange.from = moment().subtract(1, 'month').startOf('month').toDate()
    }

    rangeLastQuarter () {
      this.dateRange.to = moment().subtract(1, 'quarter').endOf('quarter').toDate()
      this.dateRange.from = moment().subtract(1, 'quarter').startOf('quarter').toDate()
    }

    rangeLastHalf () {
      this.dateRange.to = moment().toDate()
      this.dateRange.from = moment().subtract(6, 'months').toDate()
    }

    private _resetErrors () {
      this.errors.from = false
      this.errors.to = false
    }
}
