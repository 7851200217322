import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'
import About from '../views/About.vue'
import Login from '../views/Login.vue'
import Panel from '../views/Panel.vue'
import Reports from '../views/Reports.vue'
import History from '../views/History.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ResetPasswordConfirm from '../views/ResetPasswordConfirm.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/panel'
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/panel',
    name: 'Panel',
    component: Panel,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/history/:lanternId?',
    name: 'History',
    component: History,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/reset-password/confirm/:uid/:token',
    name: 'ResetPasswordConfirm',
    component: ResetPasswordConfirm,
    meta: {
      requireLogin: false
    }
  },
  { path: '*', redirect: '/panel' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL || process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Login' && store.getters['user/loggedIn']) {
    next({ name: 'Panel' })
  } else {
    if (to.meta.requireLogin && !store.getters['user/loggedIn']) {
      next({ name: 'Login' })
    } else {
      next()
    }
  }
})

export default router
