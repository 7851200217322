
import Vue from 'vue'
import LoginWrapper from '@/components/LoginWrapper.vue'
import CityAvatar from '@/components/CityAvatar.vue'
import Component from 'vue-class-component'
import { Action } from 'vuex-class'

  @Component({
    components: {
      LoginWrapper,
      CityAvatar
    }
  })
export default class RestorePassword extends Vue {
    @Action('user/resetPassword')
    resetPassword!: (email: string) => Promise<{}>

    email = ''

    submitForm () {
      this.resetPassword(this.email)
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t('RestorePasswordView.confirmation') as string,
            duration: 4000
          })
        })
    }
}
