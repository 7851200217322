
import Vue from 'vue'
import Component from 'vue-class-component'
import { Action, Getter, State } from 'vuex-class'

  @Component
export default class Navbar extends Vue {
    @Action('user/logout')
    logoutAction!: () => {}

    @Getter('user/loggedIn')
    isLoggedIn!: boolean

    @State('userData', { namespace: 'user' })
    userData!: {}

    logout () {
      this.logoutAction()
    }
}
