import { Module } from 'vuex'
import { Lanterns } from '@/services/lanterns.service'
import { JsonAPIRestResponse, LanternsStoreState } from '@/store/types'
import { Lantern } from '@/models'
import { MapBounds } from '@/types'

export const lanterns: Module<LanternsStoreState, any> = {
  namespaced: true,
  state: {
    loading: false,
    lanterns: [],
    accidents: [],
    focusedLantern: undefined,
    lanternExtraProperties: {}
  },
  getters: {
    lanterns: (state) => {
      return state.lanterns
    },
    focusedLantern: (state) => {
      return state.focusedLantern
    },
    lanternExtraProperties: (state) => {
      return state.lanternExtraProperties
    }
  },
  mutations: {
    startLoading (state) {
      state.loading = true
    },
    stopLoading (state) {
      state.loading = false
    },
    setLanterns (state, lanterns) {
      state.lanterns = lanterns
    },
    setLanternForFocus (state, lantern) {
      state.focusedLantern = lantern
    },
    removeFocusedLantern (state) {
      state.focusedLantern = undefined
    },
    setLanternExtraProperties (state, properties: { [key: string]: [string, number] }) {
      state.lanternExtraProperties = properties
    }
  },
  actions: {
    async loadLanterns ({ commit }, bounds?: MapBounds) {
      commit('startLoading')
      const lanterns = (await Lanterns.lanterns(bounds))
        .map((item: JsonAPIRestResponse<Lantern>) => {
          return {
            ...item.attributes,
            id: item.id
          }
        })

      commit('stopLoading')
      commit('setLanterns', lanterns)
    },
    async loadLanternExtraProperties ({ commit }, lanternId) {
      commit('setLanternExtraProperties', {})
      const extraProperty = await Lanterns.lanternExtraProperties(lanternId)
      commit('setLanternExtraProperties', extraProperty)
    }
  }
}
