const TOKEN_KEY = 'access_token'

export const TokenService = {
  getToken () {
    return localStorage.getItem(TOKEN_KEY)
  },
  saveToken (accessToken: string) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },
  removeToken () {
    localStorage.removeItem(TOKEN_KEY)
  }
}
