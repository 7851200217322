
import { defineComponent } from 'vue'
import { AccidentModel } from '@/models'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
  props: {
    accident: {
      type: Object as () => AccidentModel,
      required: true
    }
  },
  emits: ['updateStatus'],
  data () {
    return {
      additionalMessage: '',
      status: '',
      formError: false
    }
  },
  computed: {
    statusOptions (): { status: string; label: string | TranslateResult }[] {
      return [
        {
          status: 'in_progress',
          label: this.$t('modals.updateStatusToInProgress')
        },
        {
          status: 'false',
          label: this.$t('modals.updateStatusToFalse')
        },
        {
          status: 'finished',
          label: this.$t('modals.updateStatusToDone')
        }
      ]
    }
  },
  methods: {
    setStatus () {
      this.formError = !this.status

      if (this.formError) return

      this.$emit('updateStatus', this.accident, this.status, this.additionalMessage)
      const parent = this.$parent as any // eslint-disable-line @typescript-eslint/no-explicit-any
      parent.close()
    }
  }
})
