
import { defineComponent } from 'vue'
import MapPoints from '@/components/MapPoints.vue'
import TableAccidentDetails from '@/components/TableAccidentDetails.vue'
import ModalStatusForm from '@/components/modals/ModalStatusForm.vue'
import AccidentDetails from '@/components/table/AccidentDetails.vue'
import { AccidentModel } from '@/models'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  components: {
    MapPoints,
    ModalStatusForm,
    TableAccidentDetails,
    AccidentDetails
  },
  computed: {
    ...mapGetters({
      loading: 'accidents/loading',
      accidents: 'accidents/accidents',
      lanterns: 'lanterns/lanterns',
      focusedLantern: 'lanterns/focusedLantern',
      pagination: 'accidents/pagination'
    })
  },
  data () {
    return {
      currentPage: 0,
      perPage: 10,
      total: 0,
      defaultOpenedDetails: [1],
      showDetailIcon: true
    }
  },
  mounted () {
    this.getUsersDetails()
    this.loadAccidents({
      params: {
        'filter[status.in]': 'in_progress,new',
        sort: '-created'
      }
    })
  },
  methods: {
    ...mapActions({
      getUsersDetails: 'user/getUsersDetails',
      loadAccidents: 'accidents/loadAccidents',
      loadLanterns: 'lanterns/loadLanterns',
      loadAccidentDetails: 'accidents/loadAccidentDetails',
      updateAccidentStatus: 'accidents/updateAccidentStatus'
    }),
    showLanternOnMap (id: number) {
      this.loadAccidentDetails(id)
    },
    onPageChange (pageNumber: number) {
      if (pageNumber > this.currentPage) {
        if (this.pagination.next) {
          this.loadAccidents({
            link: this.pagination.next
          }
          )
        }
      } else {
        if (this.pagination.prev) {
          this.loadAccidents({
            link: this.pagination.prev
          }
          )
        }
      }
    },
    updateStatus (accident: AccidentModel, status: string, comment: string) {
      this.updateAccidentStatus(
        Object.assign({}, {
          ...accident
        }, {
          status,
          resolvedComment: comment
        }))
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t('Panel.updateStatusToast') as string,
            duration: 4000
          })
        })
    },

    changeStatus (accident: AccidentModel): void {
      this.$buefy.modal.open({
        parent: this as any, // eslint-disable-line @typescript-eslint/no-explicit-any
        component: ModalStatusForm as any, // eslint-disable-line @typescript-eslint/no-explicit-any
        hasModalCard: true,
        trapFocus: true,
        events: {
          updateStatus: this.updateStatus
        },
        props: {
          accident
        }
      })
    }
  },
  watch: {
    pagination (newPagination) {
      this.total = newPagination.count
      this.currentPage = newPagination.page
    }
  }
})
