
import { defineComponent } from 'vue'
import LoginWrapper from '@/components/LoginWrapper.vue'
import CityAvatar from '@/components/CityAvatar.vue'
import { NewPasswordParamsModel } from '@/models'

export default defineComponent({
  components: {
    LoginWrapper,
    CityAvatar
  },
  data () {
    return {
      newPassword: {
        password: '',
        rePassword: ''
      }
    }
  },
  methods: {
    submitForm () {
      if (this.newPassword.password !== this.newPassword.rePassword) {
        throw new Error('Password should be the same ')
      }
      this.resetPasswordConfirm({
        newPassword: this.newPassword.password,
        uid: this.$route.params.uid,
        token: this.$route.params.token
      })
    },
    resetPasswordConfirm (newPasswordParams: NewPasswordParamsModel) {
      this.$store.dispatch('user/resetPasswordConfirm', newPasswordParams)
    }
  }
})
