
import Vue from 'vue'
import Component from 'vue-class-component'
import { State } from 'vuex-class'

@Component
export default class Footer extends Vue {
    @State('cityText')
    cityText!: string

    @State('cityImage')
    cityImage!: string
}
