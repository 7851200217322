
import { defineComponent } from 'vue'
import AccidentDetailsExtraInfo from '@/components/table/AccidentDetailsExtraInfo.vue'

interface Accident {
  resolvedComment?: string;
  lantern: {
    id: number;
    street: string;
    gid: number;
  };
}

export default defineComponent({
  props: {
    accident: {
      type: Object as () => Accident,
      required: true
    }
  },
  components: {
    'accident-details-extra-info': AccidentDetailsExtraInfo
  },
  data () {
    return {
      extraInfoLoading: true
    }
  },
  mounted () {
    if (this.isExternalDataConnected) {
      this.$store.dispatch('lanterns/loadLanternExtraProperties', this.accident.lantern.gid)
        .then(() => {
          this.extraInfoLoading = false
        })
    }
  },
  computed: {
    resolvedComment (): string {
      return this.accident.resolvedComment ? this.accident.resolvedComment.replace(/\n/g, '<br>') : 'Brak'
    },
    isExternalDataConnected (): boolean {
      return this.$store.state.isExternalDataConnected
    }
  }
}
)
