import { Module } from 'vuex'
import { UserStoreState } from './types'
import { AuthenticationError, UserService } from '@/services/user.service'
import { TokenService } from '@/services/storage.service'
import router from '@/router'
import { NewPasswordParamsModel } from '@/models'

export const user: Module<UserStoreState, {}> = {
  namespaced: true,
  state: {
    authenticating: false,
    authenticationError: '',
    authenticationErrorCode: 0,
    accessToken: TokenService.getToken(),
    userData: {}
  },
  getters: {
    loggedIn: (state) => {
      return !!state.accessToken
    },
    authenticationErrorCode: (state) => {
      return state.authenticationErrorCode
    },
    authenticationError: (state) => {
      return state.authenticationError
    },
    authenticating: (state) => {
      return state.authenticating
    }
  },
  mutations: {
    loginRequest (state) {
      state.authenticating = true
      state.authenticationError = ''
      state.authenticationErrorCode = 0
    },
    loginSuccess (state, accessToken) {
      state.accessToken = accessToken
      state.authenticating = false
    },
    loginError (state, { errorCode, errorMessage }) {
      state.authenticating = false
      state.authenticationErrorCode = errorCode
      state.authenticationError = errorMessage
    },
    logoutSuccess (state) {
      state.accessToken = ''
    },
    getUsersDetailsSuccess (state, { email }) {
      state.userData.email = email
    }
  },
  actions: {
    async login ({ commit }, payload) {
      commit('loginRequest')
      try {
        const token = await UserService.login(payload.email, payload.password)
        commit('loginSuccess', token)
        await router.push({ name: 'Panel' })
      } catch (e) {
        if (e instanceof AuthenticationError) {
          commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
        }
      }
    },
    async logout ({ commit }) {
      try {
        await UserService.logout()
      } catch (e) {
        console.log(e)
      }
      commit('logoutSuccess')
      await router.push('/login')
    },
    async resetPassword ({ commit }, email) {
      await UserService.resetPassword(email)
      await router.push('/login')
    },
    async resetPasswordConfirm ({ commit }, newPasswordParams: NewPasswordParamsModel) {
      await UserService.resetPasswordConfirm(newPasswordParams)
      await router.push('/login')
    },
    async getUsersDetails ({ commit }) {
      const details = await UserService.getUsersDetails()
      commit('getUsersDetailsSuccess', {
        email: details.email
      })
    }
  }
}
