import { ApiService } from '@/services/api.service'
import { AccidentModel } from '@/models'
import { GenerateReportPayload } from '@/store/types'
import FileSaver from 'file-saver'
import { MapBounds } from '@/types'

export const Lanterns = {
  async accidents (link?: string, params: {} = {
    sort: '-created'
  }) {
    const defaultLink = 'accidents/'
    if (link) {
      params = {}
    }
    const response = await ApiService.get(link || defaultLink, {
      params
    })
    const included = response.data.included
    // add included as a type data
    response.data.data.forEach((item: any) => {
      item.relationships.lantern.data = included
        .find((inc: any) => inc.type === 'Lantern' && inc.id === item.relationships.lantern.data.id)
    })
    response.data.data.forEach((item: any) => {
      item.relationships.reason.data = included
        .find((inc: any) => inc.type === 'AccidentReason' && inc.id === item.relationships.reason.data.id)
    })
    return response.data
  },

  async newAccidents () {
    const response = await ApiService.get('accidents/')
    return response.data.data
  },

  async lanterns (bounds?: MapBounds) {
    const params = {} as { ['filter[point.contained]']: string }
    if (bounds) {
      const filterParam = `SRID=4326;POLYGON((${bounds._northEast.lng} ${bounds._northEast.lat},
                                    ${bounds._northEast.lng} ${bounds._southWest.lat},
                                    ${bounds._southWest.lng} ${bounds._southWest.lat},
                                    ${bounds._southWest.lng} ${bounds._northEast.lat},
                                    ${bounds._northEast.lng} ${bounds._northEast.lat}))`
      params['filter[point.contained]'] = filterParam.replace(/\n|\r/g, '')
    }

    const response = await ApiService.get('lanterns/', {
      params
    })
    return response.data.data
  },
  async accident (accidentId: string) {
    const response = await ApiService.get(`accidents/${accidentId}/`)
    return response.data
  },
  async updateAccident (accidentId: string, data: AccidentModel) {
    const response = await ApiService.put(`accidents/${accidentId}/`, {
      data: {
        id: accidentId,
        type: 'Accident',
        attributes: data
      }
    })
    return response.data
  },
  async lanternExtraProperties (lanternId: string) {
    const response = await ApiService.get(`lanterns/${lanternId}/extra_properties/`)
    return response.data.data
  },
  generateReport: async function (payload: GenerateReportPayload) {
    if (!payload.from || !payload.to) {
      return
    }
    const dateFrom = payload.from.toISOString().slice(0, 10)
    const dateTo = payload.to.toISOString().slice(0, 10)
    const response = await ApiService.post(
      'accidents/generate-report/',
      {
        data: {
          type: 'Accident',
          attributes: {
            dateFrom,
            dateTo
          }
        }
      },
      {
        responseType: 'blob'
      }
    )
    FileSaver.saveAs(response.data, `raport-${dateFrom}-${dateTo}.xls`)
    return response.data
  }
}
