
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    lanternGid: {
      type: Number,
      required: true
    }
  },
  computed: {
    extraFields (): {[key: string]: string | number } {
      return this.$store.getters['lanterns/lanternExtraProperties']
    }
  }
})
