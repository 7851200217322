
import Vue from 'vue'
import Component from 'vue-class-component'
import { Action, Getter, State } from 'vuex-class'
import { AccidentModel } from '@/models'
import { PaginatedDataState } from '@/store/types'
import { Watch } from 'vue-property-decorator'

import StatusView from '@/components/table/StatusField.vue'
import AccidentDetails from '@/components/table/AccidentDetails.vue'
import i18n from '@/i18n'

  @Component({
    components: {
      StatusView,
      AccidentDetails
    }
  })
export default class History extends Vue {
    @State('pagination', { namespace: 'accidents' })
    pagination!: PaginatedDataState

    @Getter('accidents/loading')
    loading!: boolean

    isDetailed = false
    searchResults = false
    searchText = ''
    statusFilter = ''

    paginationPosition = 'bottom'

    currentPage = 0
    perPage = 10
    total = 0
    defaultOpenedDetails = [1]
    showDetailIcon = true

    accidentStatuses = [
      {
        key: i18n.t('common.status.new'),
        id: 'new'
      },
      {
        key: i18n.t('common.status.inProgress'),
        id: 'in_progress'
      },
      {
        key: i18n.t('common.status.false'),
        id: 'false'
      },
      {
        key: i18n.t('common.status.resolved'),
        id: 'finished'
      }
    ]

    @Action('accidents/loadAccidents')
    loadAccidents!: (payload: { link?: string; params?: {} }) => {}

    @Getter('accidents/accidents')
    accidents!: Array<AccidentModel>

    @Watch('pagination')
    paginationWatch (pagination: PaginatedDataState) {
      this.total = pagination.count
      this.currentPage = pagination.page
    }

    mounted () {
      const payload: { link?: string; params?: {} } = {}
      // check for params is this is detailed view
      if (this.$route.params.lanternId) {
        this.isDetailed = true
        payload.params = {
          'filter[lantern]': this.$route.params.lanternId
        }
      }
      this.loadAccidents(payload)
    }

    get hasData () {
      return this.accidents && this.accidents.length
    }

    // search () {
    //   this.loadAccidents({
    //     params: this.getFilteringParams()
    //   })
    //
    //   this.updateRecordsFiltering()
    //   this.searchResults = true
    // }

    updateRecordsFiltering () {
      this.loadAccidents({
        params: this.getFilteringParams()
      })
    }

    getFilteringParams () {
      const params: { [key: string]: string } = {}
      if (this.searchText) {
        params['filter[lantern.street.icontains]'] = this.searchText
      }
      if (this.statusFilter) {
        params['filter[status]'] = this.statusFilter
      }
      if (this.$route.params.lanternId) {
        params['filter[lantern]'] = this.$route.params.lanternId
      }
      return params
    }

    resetSearch () {
      this.searchText = ''
      this.updateRecordsFiltering()
    }

    resetFiltering () {
      this.statusFilter = ''
      this.updateRecordsFiltering()
    }

    onPageChange (pageNumber: number) {
      if (pageNumber > this.currentPage) {
        if (this.pagination.next) {
          this.loadAccidents({
            link: this.pagination.next
          }
          )
        }
      } else {
        if (this.pagination.prev) {
          this.loadAccidents({
            link: this.pagination.prev
          }
          )
        }
      }
    }
}
