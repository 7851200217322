import axios, { AxiosRequestConfig } from 'axios'
import store from '../store'
import { TokenService } from '@/services/storage.service'

export const ApiService = {
  _401interceptor: null as number | null,

  init (baseUrl: string) {
    axios.defaults.baseURL = baseUrl
    axios.defaults.headers['Content-Type'] = 'application/vnd.api+json; charset=utf-8'
    axios.defaults.headers.Accept = 'application/vnd.api+json'
    this.mount401Interceptor()
  },

  setHeader () {
    axios.defaults.headers.common.Authorization = `Token ${TokenService.getToken()}`
  },

  removeHeader () {
    axios.defaults.headers.common = {}
  },

  get (resource: string, config?: AxiosRequestConfig) {
    return axios.get(resource, config)
  },

  post<T extends {}> (resource: string, data: T, config?: {}) {
    return axios.post(resource, data, config)
  },

  put<T extends {}> (resource: string, data: T) {
    return axios.put(resource, data)
  },

  delete (resource: string) {
    return axios.delete(resource)
  },

  mount401Interceptor () {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        if (error.request.status === 401 && !error.config.url.includes('token/logout/')) {
          await store.dispatch('user/logout')
          throw error
        }
        // If error was not 401 just reject as is
        throw error
      }
    )
  }

  // unmount401Interceptor () {
  //   // Eject the interceptor
  //   if (this._401interceptor) {
  //     axios.interceptors.response.eject(this._401interceptor)
  //   }
  // }
}
