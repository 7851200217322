
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    'mj-navbar': Navbar,
    'mj-footer': Footer
  }
}
