import { Module } from 'vuex'
import {
  AccidentStoreState, GenerateReportPayload,
  JsonApiPaginatedResponse,
  JsonAPIRestRelationshipWithAttributes,
  JsonAPIRestResponse, PaginatedDataState
} from '@/store/types'
import { Accident, AccidentAPIModel, AccidentModel, Lantern, Reason } from '@/models'
import { Lanterns } from '@/services/lanterns.service'

export const accidents: Module<AccidentStoreState, any> = {
  namespaced: true,
  state: {
    loading: false,
    accidents: [],
    pagination: {
      count: 0,
      first: '',
      last: '',
      next: '',
      prev: '',
      page: 0,
      pages: 0
    }
  },
  getters: {
    loading: (state) => {
      return state.loading
    },
    accidents: (state) => {
      return state.accidents
    },
    pagination: (state) => {
      return state.pagination
    }
  },
  mutations: {
    startLoading (state) {
      state.loading = true
    },
    stopLoading (state) {
      state.loading = false
    },
    setAccidents (state, accidents: Array<AccidentModel>) {
      state.accidents = accidents
    },
    setAccident (state, accident: JsonAPIRestResponse<Accident>) {
      const accidentToUpdate = state.accidents.find((value) => value.id === accident.id)
      if (accidentToUpdate) {
        accidentToUpdate.status = accident.attributes.status
        accidentToUpdate.resolvedComment = accident.attributes.resolvedComment
      }
    },
    setPaginationData (state, pagination: PaginatedDataState) {
      state.pagination = pagination
    }
  },
  actions: {
    async loadAccidents ({ commit }, payload: { link?: string; params?: {} }) {
      commit('startLoading')
      const accidentsResponse: JsonApiPaginatedResponse<AccidentAPIModel, {
        lantern: JsonAPIRestRelationshipWithAttributes<Lantern>;
        reason: JsonAPIRestRelationshipWithAttributes<Reason>;
      }> = await Lanterns.accidents(payload.link, payload.params)

      commit('setPaginationData', {
        first: accidentsResponse.links.first,
        last: accidentsResponse.links.last,
        next: accidentsResponse.links.next,
        prev: accidentsResponse.links.prev,
        count: accidentsResponse.meta.pagination.count,
        page: accidentsResponse.meta.pagination.page,
        pages: accidentsResponse.meta.pagination.pages
      } as PaginatedDataState)

      // accidentsResponse
      const accidents: Array<Accident> = accidentsResponse.data
        .map((item: JsonAPIRestResponse<AccidentAPIModel, {
          lantern: JsonAPIRestRelationshipWithAttributes<Lantern>;
          reason: JsonAPIRestRelationshipWithAttributes<Reason>;
        }>) => {
          return {
            ...item.attributes,
            id: item.id,
            lantern: item.relationships.lantern.data.attributes,
            reason: item.relationships.reason.data.attributes
          }
        })
      commit('stopLoading')
      commit('setAccidents', accidents)
    },
    async loadAccidentDetails ({ commit }, accidentId) {
      const accident = await Lanterns.accident(accidentId)
      commit('lanterns/setLanternForFocus', accident.included[1].attributes, { root: true })
    },

    async updateAccidentStatus ({ commit }, payload: AccidentModel) {
      const response = await Lanterns.updateAccident(payload.id, payload)
      commit('setAccident', response.data)
    },

    async generateReport ({ commit }, payload: GenerateReportPayload) {
      const response = await Lanterns.generateReport(payload)
    }
  }
}
