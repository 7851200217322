import i18n from '@/i18n'

export const statusNameFilter = (value: string) => {
  if (!value) return ''
  switch (value) {
    case 'new':
      return i18n.t('common.status.new')
    case 'in_progress':
      return i18n.t('common.status.inProgress')
    case 'finished':
      return i18n.t('common.status.resolved')
    case 'false':
      return i18n.t('common.status.false')
    default:
      return ''
  }
}
