
import { defineComponent } from 'vue'
import LoginWrapper from '@/components/LoginWrapper.vue'
import CityAvatar from '@/components/CityAvatar.vue'

export default defineComponent({
  components: {
    LoginWrapper,
    CityAvatar
  },
  data () {
    return {
      login: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('user/login', this.login)
    }
  },
  computed: {
    authenticationError () {
      return this.$store.getters['user/authenticationError']
    }
  }
})
