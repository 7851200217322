
import Vue from 'vue'
import Component from 'vue-class-component'
import { State } from 'vuex-class'

  @Component
export default class CityAvatar extends Vue {
    @State('cityImage')
    cityImage!: string
}
