
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { LMap, LMarker, LPopup, LTileLayer, LIcon, LControl, LCircleMarker } from 'vue2-leaflet'
import { Lantern } from '@/models'
import { mapMutations } from 'vuex'
import { Action, Mutation, State } from 'vuex-class'
import { MapBounds } from '@/types'

  @Component({
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPopup,
      LIcon,
      LControl,
      LCircleMarker
    },
    methods: {
      ...mapMutations('lanterns', [
        'removeFocusedLantern'
      ])
    }
  })
export default class Map extends Vue {
    removeFocusedLantern!: () => {}

    @Prop({ default: [] }) private lanterns!: Array<Lantern>

    @Action('lanterns/loadLanterns')
    loadLanterns!: (bounds?: MapBounds) => {}

    @Mutation('updateZoom')
    updateZoomMutation!: (zoom: number) => {}

    @Mutation('updateCenter')
    updateCenterMutation!: (center: Array<number>) => {}

    @Mutation('updateMapBounds')
    updateMapBoundsMutation!: (bounds: MapBounds) => {}

    @State('zoom')
    private zoom!: number

    @State('mapCenter')
    private mapCenter!: Array<number>

    @Prop() private focusedLantern!: Lantern

    mounted () {
      const map = this.$refs.map as LMap
      // ugly trick to pass that value without changing the type
      this.updateBounds(map.mapObject.getBounds() as unknown as MapBounds)
    }

    @Watch('focusedLantern')
    focusedLanternWatcher (lantern: Lantern | null) {
      if (lantern) {
        this.updateCenterMutation(lantern.point.coordinates.reverse())
        setTimeout(() => {
          this.updateZoomMutation(18)
        }, 500)
      } else {
        this.updateZoomMutation(16)
      }
    }

    resetLantern () {
      this.removeFocusedLantern()
    }

    updateBounds (bounds: MapBounds) {
      this.loadLanterns(bounds)
    }
}
