import Vue from 'vue'
import Vuex from 'vuex'
import { user } from '@/store/user.module'
import { lanterns } from '@/store/lanterns.module'
import { accidents } from '@/store/accidents.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mapCenter: null,
    zoom: null,
    mapBounds: null,
    cityName: '',
    cityImage: '',
    cityText: '',
    isExternalDataConnected: false
  },
  mutations: {
    updateZoom: (state, zoom) => {
      state.zoom = zoom
    },
    updateCenter: (state, center) => {
      state.mapCenter = center
    },
    updateMapBounds: (state, bounds) => {
      state.mapBounds = bounds
    },
    setCityName: (state, name: string) => {
      state.cityName = name
    },
    setCityText: (state, text) => {
      state.cityText = text
    },
    setCityImage: (state, image: string) => {
      state.cityImage = image
    },
    setIfExternalDataConnected: (state, isExternalDataConnected: boolean) => {
      state.isExternalDataConnected = isExternalDataConnected
    }
  },
  actions: {},
  modules: {
    user,
    lanterns,
    accidents
  }
})
